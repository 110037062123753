<template>
<div class="quiz-view">
    <div class="action-selection__content">

        <h1>Svago</h1>
        <p v-if="question != null">
            {{ question.question }}
        </p>

        <PtySelector style="width: 100%;" v-if="question != null" :options="question.answers.map(e => ({
            id: e.id,
            title: e.answer,
            icon: e.icon
        }))" v-model="selectedAnswers" :value="selectedAnswers" colorizeMode="stroke" iconType="boxicon" block size="small" :showCheck="true" :gap="1" :multiple="question.is_multiple"/>

    </div>
    <PtyNext :steps="0" :current="0" @next="nextQuiz" />
</div>
</template>

    
<script>
import {quizService} from '@/rest';
import PtyNext from '../components/PtyNext.vue';
import PtySelector from '../components/PtySelector.vue';
export default {
    name: 'Quiz',
    components: {
        PtyNext,
        PtySelector
    },
    props: {
        event: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        selectedAnswers: [],

        quiz: null,
        question: null,

        answers: [] // persistent answers
    }),

    methods: {
        async nextQuiz(){
            if(this.selectedAnswers.length == 0){
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Seleziona almeno una risposta',
                    position: 'top-center',
                    border: 'primary',
                });
                return;
            }
            this.answers.push(...this.selectedAnswers);

            // next
            const nextIndex = this.quiz.questions.findIndex(e => e.id == this.question.id) + 1;
            if(nextIndex < this.quiz.questions.length){
                this.question = this.quiz.questions[nextIndex];
            } else {
                this.quizEnded();
            }
        },

        async quizEnded(){
            try {
                const result = await quizService.answer({
                    quiz_id: this.quiz.id,
                    answer_ids: this.answers
                });

                if(!result || result.status != 200){
                    throw new Error('Failed to answer quiz');
                }

                 // if previous route is chat
                if(this.afterChat){
                    this.$router.push({name: 'Chat', params: {
                        id: 1
                    }})
                } else {
                    this.$router.push({name: 'Waiting', params: {
                        afterQuiz: true
                    }})
                }
            } catch (error) {
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Errore durante il salvataggio delle risposte',
                    position: 'top-center',
                    border: 'primary',
                });
            }

           
        }
    },

    async mounted(){
        if(!this.event){
            this.$router.push({name: 'OnBoarding'})
        } else {
            try {
                this.quiz = await quizService.detail({
                    id: this.event.quiz_id
                });

                let oldQuestions = localStorage.getItem('QUIZ_ANSWERS');
                if(oldQuestions){
                    oldQuestions = JSON.parse(oldQuestions);
                    if(oldQuestions.event_id == this.event.id && oldQuestions.quiz_id == this.event.quiz_id){
                        this.answers = oldQuestions.answers;

                        // find last answered question
                        for(let i = 0; i < this.quiz.questions.length; i++){
                            if(this.answers.find(e => e == this.quiz.questions[i].id)){
                                continue;
                            } else {
                                this.question = this.quiz.questions[i];
                                break;
                            }
                        }
                    }
                }

                if(!this.question){
                    this.question = this.quiz.questions[0];
                }
                
            } catch (error) {
                console.log(error);
            }
        }
    },

    watch: {
        selectedAnswers(n){
            localStorage.setItem('QUIZ_ANSWERS', JSON.stringify({
                event_id: this.event.id,
                quiz_id: this.event.quiz_id,
                answers: n
            }))
        }
    }
}
</script>

    
<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 3rem;
    width: 100%;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #000;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}

.quiz-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background: 
    url(../assets/images/illustrations/logos.png) top/130% auto no-repeat,
    linear-gradient(180deg, rgb(210, 251, 253) 16.57%, rgb(255, 255, 255) 53.63%);

}
</style>
